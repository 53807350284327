<!--
 * @Author: 冯杰
 * @Date: 2021-10-21 09:23:00
 * @LastEditTime: 2021-11-08 13:41:37
 * @FileName: 活动配置组件
-->
<template>
  <div>
    <!-- 产品维度 -->
    <div class="flex">
      <div style="display: flex">
        <span>产品维度：</span>
        <el-select :disabled="notView" v-model="productLevel" @change="clearSelectProduct" style="width:100px">
          <el-option v-for="item in productLevelList" :key="item.dictCode" :label="item.dictValue" :value="item.dictCode">
          </el-option>
        </el-select>
      </div>
      <el-button :disabled="notView" type="primary" icon="el-icon-plus" @click="addPrizeLevel">新增奖品等级</el-button>
    </div>

    <!-- 添加政策 -->
    <div style="margin: 20px 0">
      <div :style="{ maxHeight: maxTableHeight, overflowY: 'auto' }">
        <div class="rowSection">
          <!-- 产品 -->
          <div style="background: #f9f9f9;padding: 0 20px;margin:10px 0">
            <div class="flex">
              <span>产品：</span>
              <a v-if="!notView && productLevel !== 'all' && !notEdit" style="line-height: 36px" class="el-icon-circle-plus-outline" @click="addProduct">添加产品</a>
            </div>
            <el-tag v-for="tag in quantifyRanges" :key="tag.materialCode" :closable="!notView && !notEdit" style="margin-right: 5px" effect="plain" @close="() => closeTag(tag)">
              {{ tag.materialName }}
            </el-tag>
          </div>
          <table>
            <tr>
              <td>
                <i class="requiredStyle">*</i>奖品等级名称</td>
              <td>
                <i class="requiredStyle">*</i>价值</td>
              <td>
                <i class="requiredStyle">*</i>奖品名称</td>
              <td>
                <i class="requiredStyle">*</i>奖品图片</td>
              <td>
                <i class="requiredStyle">*</i>奖品库存</td>
              <td>奖品剩余库存</td>
              <td>
                <i class="requiredStyle">*</i>单次奖励数量</td>
              <td>
                <i class="requiredStyle">*</i>每日中奖上限</td>
              <td>
                <i class="requiredStyle">*</i>中奖概率（%）</td>
              <td>终端反向奖励类型</td>
              <td>终端反向奖励数量</td>
              <td>
                <i class="requiredStyle">*</i>所占格子数</td>
              <td>操作</td>
            </tr>
            <tr v-for="(item,index) in tableList" :key="index">
              <td>
                <el-input v-model="item.prizeLevelName" placeholder="请输入内容" :disabled="item.prizeLevelName === '谢谢参与'? true:notView"></el-input>
              </td>
              <td>
                <el-input-number v-model="item.amount" :controls="false" :precision="2" :step="0.1" :disabled="item.prizeLevelName === '谢谢参与'? true:notView"></el-input-number>
              </td>
              <td>
                <el-tag :disabled="notView" v-for="(tag,tIndex) in item.prizeProductEntities" :key="tIndex" :closable='!notView' type="info" @close='() => closePrizeTag(tag, index)'>
                  {{tag.productName}}
                </el-tag>
                <el-link :underline="false" @click="()=>addPrize(index)" :disabled="item.prizeLevelName === '谢谢参与'? true:notView">添加奖品</el-link>
              </td>
              <td>
                <el-upload class="avatar-uploader" action="/upload/uploadController/uploadOssList" :on-success="(res,file)=>handleSuccess(index,res,file)" :show-file-list="false" :before-upload="beforeAvatarUpload" accept=".jpg,.jpeg,.png,.JPG,.JPEG,.PNG,"
                :disabled="item.prizeLevelName === '谢谢参与'? true:notView">
                  <img v-if="item.url" :src="item.url" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </td>
              <td>
                <el-input-number v-model="item.inventoryQuantity" :controls="false" @change="()=>stockChange(index)" :disabled="item.prizeLevelName === '谢谢参与' || detailMark !== ''? true: editMark === ''? notView:!notView">
                </el-input-number>
              </td>
              <td>
                <el-input-number v-model="item.remainingInventoryQuantity" @change="(currentValue, oldValue)=>remainStockChange(index,currentValue, oldValue)" :controls="false" :disabled="item.prizeLevelName === '谢谢参与' || detailMark !== ''? true:editMark === ''? !notView:notView">
                </el-input-number>
              </td>
              <td>
                <el-input-number v-model="item.quantity" :controls="false" :disabled="item.prizeLevelName === '谢谢参与' || detailMark !== ''? true: editMark === ''? notView:!notView"></el-input-number>
              </td>
              <td>
                <el-input-number v-model="item.maxQuantity" :controls="false" :disabled="item.prizeLevelName === '谢谢参与'? true:notView"></el-input-number>
              </td>
              <td>
                <el-input-number v-model="item.probability" :controls="false" :disabled="notView"></el-input-number>
              </td>
              <td>
                <el-select v-model="item.reverseType" placeholder="请选择" :disabled="item.prizeLevelName === '谢谢参与'? true:notView">
                  <el-option v-for="item in rewardList" :key="item.key" :label="item.name" :value="item.value">
                  </el-option>
                </el-select>
              </td>
              <td>
                <el-input-number v-model="item.reverse" :controls="false" :disabled="item.prizeLevelName === '谢谢参与'? true:notView"></el-input-number>
              </td>
              <td>
                <el-input-number v-model="item.grids" :controls="false" :disabled="notView"></el-input-number>
              </td>
              <td>
                <el-link :underline="false" :disabled="item.prizeLevelName === '谢谢参与'? true:notView" @click="()=>deleteActivity(index)">删除</el-link>
              </td>
            </tr>
          </table>

        </div>
      </div>
    </div>

    <SelectConfig ref="productRef" @onGetSelect="productConfirm" />
  </div>
</template>

<script>
import request from '@/found/utils/request';
import SelectConfig from '@/found/components/select_org_cus_ter/select_config.vue';

export default {
  name: 'lottery-config',
  components: {
    SelectConfig,
  },
  created() {
    this.getDict();
    // 确保表格占满全屏
    this.maxTableHeight = `${document.querySelector('.drawer-container')
      .clientHeight
      - 30
      - 48}px`;
    console.log(this.notView);
  },
  data() {
    return {
      maxTableHeight: '500px',
      productLevel: 'material',
      quantifyRanges: [],
      productLevelList: [],
      rewardList: [],
      tableData: [],
      tableList: [
        {
          prizeLevel: 1,
          prizeLevelName: '谢谢参与',
          amount: 0,
          prizeProductEntities: [],
          // fileList: [],
          url: '',
          inventoryQuantity: 0,
          remainingInventoryQuantity: 0,
          quantity: 0,
          maxQuantity: 0,
          probability: 0,
          reverseType: '',
          grids: 0,
          reverse: 0,
          isShow: 'disShow',
          baseCount: 0,
        },
      ],
      notView: false,
      editMark: '',
      detailMark: '',
    };
  },
  watch: {},
  methods: {
    // 获取数据字典
    getDict() {
      // 产品维度
      request
        .post('/mdm/mdmdictdata/list', {
          dictTypeCode: 'cps-product-level',
        })
        .then((res) => {
          if (!res.success) return this.$message.error(res.message);
          // this.productLevelList = res.result;
          this.productLevelList = res.result.filter(
            (item) => item.dictCode === 'material',
          );
        });
      // 奖励类型
      request.get('/cps/v1/reward/mount/findReward').then((res) => {
        if (!res.success) return this.$message.error(res.message);
        res.result.forEach((item) => {
          const data = item;
          data.value = item.flag;
          return data;
        });
        this.rewardList = res.result.filter((item) => item.name === '积分');
      });
    },

    closeTag(val) {
      this.quantifyRanges = this.quantifyRanges.filter(
        (item) => item.materialCode !== val.materialCode,
      );
    },

    // 添加产品
    addProduct() {
      let functionCode = 'treety_modules_productList';
      let idKey = 'productAndLevelCode';
      // let codes = [];
      if (this.productLevel === 'material') {
        functionCode = 'treety_modules_productList_material';
        idKey = 'materialCode';
        // codes = this.quantifyRanges.map((item) => item.materialCode);
      } else if (this.productLevel === 'all') {
        functionCode = 'treety_modules_productList';
        idKey = 'productAndLevelCode';
      } else if (this.productLevel === 'materialGroup') {
        functionCode = 'treety_modules_productList_materialGroup';
        idKey = 'materialGroupCode';
      } else {
        functionCode = 'treety_modules_productList_levelType';
        idKey = 'productLevelCode';
      }

      let productLevelType = null;
      switch (this.productLevel) {
        case 'brand':
          productLevelType = 'brand';
          break;
        case 'series':
          productLevelType = 'series';
          break;
        case 'max':
          productLevelType = 'max';
          break;
        case 'min':
          productLevelType = 'min';
          break;
        default:
          productLevelType = null;
          break;
      }

      const params = {
        functionCode,
        data: this.quantifyRanges,
        selectionList: [],
        idKey,
        noReset: true,
        paramData: {
          productLevelType,
        },
      };
      this.clickType = 'cp';
      this.$refs.productRef.openSelectModal(params);
    },

    clearSelectProduct(val) {
      this.quantifyRanges = [];
      this.tableData.forEach((item) => {
        const data = item;
        if (val === 'all') {
          data.dimensionFlag = 'DIMENSION_ALL';
          data.dimensionName = '全部产品';
        } else {
          let dimensionName = '';
          this.productLevelList.forEach((p) => {
            if (p.dictCode === this.productLevel) dimensionName = p.dictValue;
          });
          data.dimensionFlag = val;
          data.dimensionName = dimensionName;
        }
        data.quantifyRanges = [];
        return data;
      });
    },
    productConfirm(params) {
      console.log('商品弹框确认带出的数据', params);
      if (this.clickType === 'jp') {
        params.forEach((val) => {
          this.tableList[this.tableIndex].prizeProductEntities.push({
            productCode: val.materialCode,
            productName: val.materialName,
          });
        });
      } else if (this.clickType === 'cp') {
        const quantifyRanges = [];
        // this.quantifyRanges = [];
        params.forEach((item) => {
          if (this.productLevel === 'all') {
            quantifyRanges.push({
              materialName: item.productAndLevelName,
              materialCode: item.productAndLevelCode,
            });
          } else if (this.productLevel === 'material') {
            quantifyRanges.push({
              materialName: item.materialName,
              materialCode: item.materialCode,
            });
          } else if (this.productLevel === 'materialGroup') {
            quantifyRanges.push({
              materialName: item.materialGroupName,
              materialCode: item.materialGroupCode,
            });
          } else {
            quantifyRanges.push({
              materialName: item.productLevelName,
              materialCode: item.productLevelCode,
            });
          }
        });
        console.log(this.quantifyRanges);
        if (this.quantifyRanges && this.quantifyRanges.length > 0) {
          const arr = this.quantifyRanges.map((item) => item.materialCode);

          quantifyRanges.forEach((item) => {
            if (!arr.includes(item.materialCode)) {
              this.quantifyRanges.push(item);
            }
          });
        } else {
          this.quantifyRanges = [...quantifyRanges];
        }

        console.log(quantifyRanges);
      }
    },

    beforeAvatarUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        this.$message.error('上传图片大小不能超过 5MB!');
      }
      return isLt5M;
    },
    handleSuccess(index, res, file) {
      console.log('图片', res);
      if (res.code === 200 && res.success) {
        res.result.forEach((v) => {
          this.tableList[index].url = v.url;
        });
      }
    },
    // 新增奖品等级
    addPrizeLevel() {
      this.tableList.push({
        prizeLevel: this.tableList.length + 1,
        prizeLevelName: '',
        amount: 0,
        prizeProductEntities: [],
        url: '',
        inventoryQuantity: 0,
        remainingInventoryQuantity: 0,
        quantity: 0,
        maxQuantity: 0,
        probability: 0,
        reverseType: '',
        grids: 0,
        reverse: 0,
        isShow: 'disShow',
      });
    },
    // 添加奖品
    addPrize(index) {
      const params = {
        functionCode: 'add_prize_list',
        data: [],
        selectionList: [],
        noReset: true,
        paramData: {
          productLevelType: 'prize',
        },
      };
      this.tableIndex = index;
      this.clickType = 'jp';
      this.$refs.productRef.openSelectModal(params);
    },
    // 库存改变 剩余库存改变
    stockChange(index) {
      this.tableList[index].remainingInventoryQuantity = this.tableList[
        index
      ].inventoryQuantity;
    },
    // 剩余库存改变 奖品库存改变
    remainStockChange(index, currentValue, oldValue) {
      let cut = 0;
      cut = oldValue - currentValue;
      this.tableList[index].inventoryQuantity -= cut;
    },
    // 删除行
    deleteActivity(index) {
      this.tableList.splice(index, 1);
    },
    // 删除奖品tag
    closePrizeTag(val, index) {
      this.tableList[index].prizeProductEntities = this.tableList[
        index
      ].prizeProductEntities.filter(
        (item) => item.productCode !== val.productCode,
      );
    },
  },
};
</script>

<style lang="less" scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
.requiredStyle {
  color: red;
  margin-right: 5px;
}
.disShow {
  display: block;
}
.disNone {
  display: none;
  /deep/ .el-upload {
    display: none;
  }
}
.weekly {
  display: flex;
  margin: 10px 0;
}

.el-input {
  display: flex;
  .el-input-group__append {
    height: 32px;
  }
  .apend {
    display: inline-block;
    // height: 30px;
    line-height: 30px;
  }
}
.el-input-number {
  width: 80px;
}

.flex {
  display: flex;
  justify-content: space-between;
}
.centerDisplay {
  display: flex;
  justify-content: center;
  align-items: center;
}

.titleflex {
  display: flex;
  justify-content: space-between;
}
table,
tr,
td {
  border: 1px solid #e8eaec;
  text-align: center;
}
td {
  width: 8%;
  padding: 0 2px;
}
table {
  width: 100%;
  border-collapse: collapse;
}
.iconpostion {
  position: relative;
  top: -5px;
  right: 10px;
  font-weight: 700;
}

.titleposition {
  display: flex;
  span {
    flex: 1;
  }
}
.contentflex {
  display: flex;
  justify-content: space-around;

  div {
    flex: 1;
  }
}

.rowLineIcon {
  font-size: 16px;
  height: 32px;
  line-height: 32px;
  padding: 0 10px;
}
.closeIcon {
  color: #f56c6c !important;
}

.rowSection {
  position: relative;
  border: 1px solid #d3d5d8;
  border-radius: 5px;
  padding: 20px;
  margin: 10px 0;
}

.close {
  display: inline-block;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.45) !important;
  position: absolute;
  right: 2px;
  top: 2px;

  &:hover {
    color: rgba(0, 0, 0, 0.8) !important;
  }
}

.tableItem {
  width: 200px;
  margin-bottom: 10px;
}

.requer {
  &::before {
    content: "*";
    color: #f56c6c;
    margin-right: 4px;
  }
}
</style>
