/*
 * @Author: 冯杰
 * @Date: 2021-10-20 14:59:47
 * @LastEditTime: 2021-10-27 21:58:05
 * @FileName: 抽奖活动列表
 */
import TablePage from '@/found/components/table_page';
import Modal from '@/found/components/modal';
import request from '@/found/utils/request';
import ConfActivity from './form/index.js';

export default {
  name: 'LuckyDrawList',
  extends: TablePage,
  data() {
    return {
      requestUrl: '/cps/v1/activity/rewardActivity/findByConditions',
      requestType: 'GET',
    };
  },
  components: {
    Modal,
    ConfActivity,
  },
  methods: {
    modalClick({ val, row }) {
      let urls = '';
      let text = '';
      this.formConfig = {
        ...this.formConfig,
      };
      if (val.code === 'add') {
        // 新增
        this.formName = 'ConfActivity';
        this.modalConfig.title = '消费者抽奖活动配置';
        this.openFull();
      } else if (val.code === 'edit') {
        // 编辑
        this.formName = 'ConfActivity';
        this.modalConfig.title = '编辑';
        this.formConfig.editId = row.id;
        this.openFull();
      } else if (val.code === 'view') {
        // 详情
        this.formName = 'ConfActivity';
        this.modalConfig.title = '详情';
        this.formConfig.id = row.id;
        this.openFull();
      } else if (val.code === 'enable') {
        // 启用
        urls = '/cps/v1/activity/rewardActivity/enable';
        text = '启用';
        if (row.id) {
          this.selection.push(row.id);
        }
        this.operationClick(urls, text);
      } else if (val.code === 'disable') {
        // 禁用
        urls = '/cps/v1/activity/rewardActivity/disable';
        text = '禁用';
        if (row.id) {
          this.selection.push(row.id);
        }
        this.operationClick(urls, text);
      }
    },
    clickVisible({ val, row }) {
      if (val.code === 'edit' && row.enableStatus === '009') {
        return false;
      }
      return true;
    },
    operationClick(urls, text) {
      let datas = [];
      if (this.selection.length > 0) {
        datas = this.selection;
        request.patch(urls, datas).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: `${text}成功`,
            });
            this.selection = [];
            this.getList();
          }
        });
      } else {
        this.$message({
          type: 'error',
          message: '请选择数据',
        });
      }
    },
  },
  created() {
    // 重写获取配置信息
    this.getConfigList('lucky_draw_list');
  },
};
