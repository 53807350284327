var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "flex" },
        [
          _c(
            "div",
            { staticStyle: { display: "flex" } },
            [
              _c("span", [_vm._v("产品维度：")]),
              _c(
                "el-select",
                {
                  staticStyle: { width: "100px" },
                  attrs: { disabled: _vm.notView },
                  on: { change: _vm.clearSelectProduct },
                  model: {
                    value: _vm.productLevel,
                    callback: function ($$v) {
                      _vm.productLevel = $$v
                    },
                    expression: "productLevel",
                  },
                },
                _vm._l(_vm.productLevelList, function (item) {
                  return _c("el-option", {
                    key: item.dictCode,
                    attrs: { label: item.dictValue, value: item.dictCode },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-button",
            {
              attrs: {
                disabled: _vm.notView,
                type: "primary",
                icon: "el-icon-plus",
              },
              on: { click: _vm.addPrizeLevel },
            },
            [_vm._v("新增奖品等级")]
          ),
        ],
        1
      ),
      _c("div", { staticStyle: { margin: "20px 0" } }, [
        _c(
          "div",
          { style: { maxHeight: _vm.maxTableHeight, overflowY: "auto" } },
          [
            _c("div", { staticClass: "rowSection" }, [
              _c(
                "div",
                {
                  staticStyle: {
                    background: "#f9f9f9",
                    padding: "0 20px",
                    margin: "10px 0",
                  },
                },
                [
                  _c("div", { staticClass: "flex" }, [
                    _c("span", [_vm._v("产品：")]),
                    !_vm.notView && _vm.productLevel !== "all" && !_vm.notEdit
                      ? _c(
                          "a",
                          {
                            staticClass: "el-icon-circle-plus-outline",
                            staticStyle: { "line-height": "36px" },
                            on: { click: _vm.addProduct },
                          },
                          [_vm._v("添加产品")]
                        )
                      : _vm._e(),
                  ]),
                  _vm._l(_vm.quantifyRanges, function (tag) {
                    return _c(
                      "el-tag",
                      {
                        key: tag.materialCode,
                        staticStyle: { "margin-right": "5px" },
                        attrs: {
                          closable: !_vm.notView && !_vm.notEdit,
                          effect: "plain",
                        },
                        on: {
                          close: function () {
                            return _vm.closeTag(tag)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(tag.materialName) + " ")]
                    )
                  }),
                ],
                2
              ),
              _c(
                "table",
                [
                  _vm._m(0),
                  _vm._l(_vm.tableList, function (item, index) {
                    return _c("tr", { key: index }, [
                      _c(
                        "td",
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入内容",
                              disabled:
                                item.prizeLevelName === "谢谢参与"
                                  ? true
                                  : _vm.notView,
                            },
                            model: {
                              value: item.prizeLevelName,
                              callback: function ($$v) {
                                _vm.$set(item, "prizeLevelName", $$v)
                              },
                              expression: "item.prizeLevelName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          _c("el-input-number", {
                            attrs: {
                              controls: false,
                              precision: 2,
                              step: 0.1,
                              disabled:
                                item.prizeLevelName === "谢谢参与"
                                  ? true
                                  : _vm.notView,
                            },
                            model: {
                              value: item.amount,
                              callback: function ($$v) {
                                _vm.$set(item, "amount", $$v)
                              },
                              expression: "item.amount",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          _vm._l(
                            item.prizeProductEntities,
                            function (tag, tIndex) {
                              return _c(
                                "el-tag",
                                {
                                  key: tIndex,
                                  attrs: {
                                    disabled: _vm.notView,
                                    closable: !_vm.notView,
                                    type: "info",
                                  },
                                  on: {
                                    close: function () {
                                      return _vm.closePrizeTag(tag, index)
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(tag.productName) + " ")]
                              )
                            }
                          ),
                          _c(
                            "el-link",
                            {
                              attrs: {
                                underline: false,
                                disabled:
                                  item.prizeLevelName === "谢谢参与"
                                    ? true
                                    : _vm.notView,
                              },
                              on: {
                                click: function () {
                                  return _vm.addPrize(index)
                                },
                              },
                            },
                            [_vm._v("添加奖品")]
                          ),
                        ],
                        2
                      ),
                      _c(
                        "td",
                        [
                          _c(
                            "el-upload",
                            {
                              staticClass: "avatar-uploader",
                              attrs: {
                                action:
                                  "/upload/uploadController/uploadOssList",
                                "on-success": function (res, file) {
                                  return _vm.handleSuccess(index, res, file)
                                },
                                "show-file-list": false,
                                "before-upload": _vm.beforeAvatarUpload,
                                accept: ".jpg,.jpeg,.png,.JPG,.JPEG,.PNG,",
                                disabled:
                                  item.prizeLevelName === "谢谢参与"
                                    ? true
                                    : _vm.notView,
                              },
                            },
                            [
                              item.url
                                ? _c("img", {
                                    staticClass: "avatar",
                                    attrs: { src: item.url },
                                  })
                                : _c("i", {
                                    staticClass:
                                      "el-icon-plus avatar-uploader-icon",
                                  }),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          _c("el-input-number", {
                            attrs: {
                              controls: false,
                              disabled:
                                item.prizeLevelName === "谢谢参与" ||
                                _vm.detailMark !== ""
                                  ? true
                                  : _vm.editMark === ""
                                  ? _vm.notView
                                  : !_vm.notView,
                            },
                            on: {
                              change: function () {
                                return _vm.stockChange(index)
                              },
                            },
                            model: {
                              value: item.inventoryQuantity,
                              callback: function ($$v) {
                                _vm.$set(item, "inventoryQuantity", $$v)
                              },
                              expression: "item.inventoryQuantity",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          _c("el-input-number", {
                            attrs: {
                              controls: false,
                              disabled:
                                item.prizeLevelName === "谢谢参与" ||
                                _vm.detailMark !== ""
                                  ? true
                                  : _vm.editMark === ""
                                  ? !_vm.notView
                                  : _vm.notView,
                            },
                            on: {
                              change: function (currentValue, oldValue) {
                                return _vm.remainStockChange(
                                  index,
                                  currentValue,
                                  oldValue
                                )
                              },
                            },
                            model: {
                              value: item.remainingInventoryQuantity,
                              callback: function ($$v) {
                                _vm.$set(
                                  item,
                                  "remainingInventoryQuantity",
                                  $$v
                                )
                              },
                              expression: "item.remainingInventoryQuantity",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          _c("el-input-number", {
                            attrs: {
                              controls: false,
                              disabled:
                                item.prizeLevelName === "谢谢参与" ||
                                _vm.detailMark !== ""
                                  ? true
                                  : _vm.editMark === ""
                                  ? _vm.notView
                                  : !_vm.notView,
                            },
                            model: {
                              value: item.quantity,
                              callback: function ($$v) {
                                _vm.$set(item, "quantity", $$v)
                              },
                              expression: "item.quantity",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          _c("el-input-number", {
                            attrs: {
                              controls: false,
                              disabled:
                                item.prizeLevelName === "谢谢参与"
                                  ? true
                                  : _vm.notView,
                            },
                            model: {
                              value: item.maxQuantity,
                              callback: function ($$v) {
                                _vm.$set(item, "maxQuantity", $$v)
                              },
                              expression: "item.maxQuantity",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          _c("el-input-number", {
                            attrs: { controls: false, disabled: _vm.notView },
                            model: {
                              value: item.probability,
                              callback: function ($$v) {
                                _vm.$set(item, "probability", $$v)
                              },
                              expression: "item.probability",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: "请选择",
                                disabled:
                                  item.prizeLevelName === "谢谢参与"
                                    ? true
                                    : _vm.notView,
                              },
                              model: {
                                value: item.reverseType,
                                callback: function ($$v) {
                                  _vm.$set(item, "reverseType", $$v)
                                },
                                expression: "item.reverseType",
                              },
                            },
                            _vm._l(_vm.rewardList, function (item) {
                              return _c("el-option", {
                                key: item.key,
                                attrs: { label: item.name, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          _c("el-input-number", {
                            attrs: {
                              controls: false,
                              disabled:
                                item.prizeLevelName === "谢谢参与"
                                  ? true
                                  : _vm.notView,
                            },
                            model: {
                              value: item.reverse,
                              callback: function ($$v) {
                                _vm.$set(item, "reverse", $$v)
                              },
                              expression: "item.reverse",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          _c("el-input-number", {
                            attrs: { controls: false, disabled: _vm.notView },
                            model: {
                              value: item.grids,
                              callback: function ($$v) {
                                _vm.$set(item, "grids", $$v)
                              },
                              expression: "item.grids",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          _c(
                            "el-link",
                            {
                              attrs: {
                                underline: false,
                                disabled:
                                  item.prizeLevelName === "谢谢参与"
                                    ? true
                                    : _vm.notView,
                              },
                              on: {
                                click: function () {
                                  return _vm.deleteActivity(index)
                                },
                              },
                            },
                            [_vm._v("删除")]
                          ),
                        ],
                        1
                      ),
                    ])
                  }),
                ],
                2
              ),
            ]),
          ]
        ),
      ]),
      _c("SelectConfig", {
        ref: "productRef",
        on: { onGetSelect: _vm.productConfirm },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", [
        _c("i", { staticClass: "requiredStyle" }, [_vm._v("*")]),
        _vm._v("奖品等级名称"),
      ]),
      _c("td", [
        _c("i", { staticClass: "requiredStyle" }, [_vm._v("*")]),
        _vm._v("价值"),
      ]),
      _c("td", [
        _c("i", { staticClass: "requiredStyle" }, [_vm._v("*")]),
        _vm._v("奖品名称"),
      ]),
      _c("td", [
        _c("i", { staticClass: "requiredStyle" }, [_vm._v("*")]),
        _vm._v("奖品图片"),
      ]),
      _c("td", [
        _c("i", { staticClass: "requiredStyle" }, [_vm._v("*")]),
        _vm._v("奖品库存"),
      ]),
      _c("td", [_vm._v("奖品剩余库存")]),
      _c("td", [
        _c("i", { staticClass: "requiredStyle" }, [_vm._v("*")]),
        _vm._v("单次奖励数量"),
      ]),
      _c("td", [
        _c("i", { staticClass: "requiredStyle" }, [_vm._v("*")]),
        _vm._v("每日中奖上限"),
      ]),
      _c("td", [
        _c("i", { staticClass: "requiredStyle" }, [_vm._v("*")]),
        _vm._v("中奖概率（%）"),
      ]),
      _c("td", [_vm._v("终端反向奖励类型")]),
      _c("td", [_vm._v("终端反向奖励数量")]),
      _c("td", [
        _c("i", { staticClass: "requiredStyle" }, [_vm._v("*")]),
        _vm._v("所占格子数"),
      ]),
      _c("td", [_vm._v("操作")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }