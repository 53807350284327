/*
 * @Author: 冯杰
 * @Date: 2021-10-20 17:06:44
 * @LastEditTime: 2021-11-08 13:40:37
 * @FileName: 新增/编辑 消费者抽奖活动配置
 */
import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
import AddConfig from '../components/addConfiguration.vue';

formCreate.component('AddConfig', AddConfig);

export default {
  extends: Form,
  components: {
    // 挂载组件
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      formFunctionCode: 'lottery_configuration',
    };
  },
  methods: {
    getActivityById() {
      request
        .get('/cps/v1/activity/rewardActivity/findDetailById', {
          id: this.formConfig.id ? this.formConfig.id : this.formConfig.editId,
        })
        .then((res) => {
          if (!res.success) return this.$message.error(res.message);
          console.log('根据id查询数据', res);
          this.setValue({
            ...res.result,
            dateTime: [res.result.startTime, res.result.endTime],
          });
          this.fApi.el('AddConfig').tableList = res.result.prize.map((v) => {
            const val = v;
            if (val.reverseType === null) {
              val.reverseType = '';
            } else {
              val.reverseType = `${val.reverseType}`;
            }
            return val;
          });
          this.fApi.el('AddConfig').quantifyRanges = res.result.productEntities;
          this.fApi.el('AddConfig').productLevel = res.result.productType;
          this.fApi.el('AddConfig').notView = !!this.formConfig.id;
          this.fApi.el('AddConfig').notEdit = !!this.formConfig.editId;
          this.fApi.el('AddConfig').editMark = this.formConfig.editId ? this.formConfig.editId : '';
          this.fApi.el('AddConfig').detailMark = this.formConfig.id ? this.formConfig.id : '';
        });
    },
    setRule(v) {
      const item = v;
      if (item.field === 'dateTime') {
        item.props = {
          ...item.props,
          'unlink-panels': true,
          type: 'datetimerange',
          'default-time': ['00:00:00', '23:59:59'],
        };
      }
      return item;
    },
    formComplete() {
      const dateTime = this.getRule('dateTime');
      dateTime.props = {
        ...dateTime.props,
        pickerOptions: {
          disabledDate: (time) => time.getTime() < Date.now() - 8.64e7,
        },
      };
      if (this.formConfig.id || this.formConfig.editId) {
        this.getActivityById();
      }
    },

    // 提交
    submit() {
      let formData = this.getFormData();
      const addFormConfig = this.fApi.el('AddConfig');
      /**
       * 表单数据组装
      */
      formData = {
        ...formData,
        startTime: formData.dateTime[0],
        endTime: formData.dateTime[1],
      };

      /**
       * 表格相关必填项数据校验
       */
      if (addFormConfig.tableList.length >= 1) {
        addFormConfig.tableList.forEach((item) => {
          if (item.prizeLevelName === '谢谢参与') {
            if (item.probability <= 0) return this.$message.error('请填写中奖概率');
            if (item.grids <= 0) return this.$message.error('请填写所占格子数');
          } else {
            if (item.prizeLevelName === '') return this.$message.error('请填写奖品等级名称');
            if (item.amount <= 0) return this.$message.error('请填写奖品价值');
            if (item.prizeProductEntities.length <= 0) return this.$message.error('请添加奖品名称');
            if (item.url === '') return this.$message.error('请上传奖励图片');
            if (item.inventoryQuantity <= 0) return this.$message.error('请填写奖品库存');
            if (item.quantity <= 0) return this.$message.error('请填写单次奖励数量');
            if (item.maxQuantity <= 0) return this.$message.error('请填写每日中奖上限');
            if (item.probability <= 0) return this.$message.error('请填写中奖概率');
            if (item.grids <= 0) return this.$message.error('请填写所占格子数');
          }
        });
        let probabilityNum = 0;
        let gridsNum = 0;
        addFormConfig.tableList.map((v) => {
          const val = v;
          probabilityNum += val.probability;
          gridsNum += val.grids;
          return val;
        });
        if (probabilityNum < 100 || probabilityNum > 100) {
          return this.$message.error('中奖概率总和需等于100%');
        }
        if (gridsNum < 12 || gridsNum > 12) {
          return this.$message.error('所占格子数总和应等于12');
        }
      }
      /**
       * 组装请求数据
       */
      let rewardActivity = {};
      let prize = [];// 奖品信息
      let productEntities = [];// 产品
      prize = addFormConfig.tableList;
      productEntities = addFormConfig.quantifyRanges;
      rewardActivity = {
        ...formData,
        prize,
        productEntities,
        productType: addFormConfig.productLevel,
        id: this.formConfig.editId ? this.formConfig.editId : '',
      };

      if (this.formConfig.editId) {
        console.log('edit', rewardActivity);
        request.patch('/cps/v1/activity/rewardActivity', rewardActivity).then((res) => {
          console.log('res', res);
          if (!res.success) return this.$message.error(res.message);
          this.$message.success(res.message);
          this.$emit('onClose');
          this.$emit('onGetList');
        });
      } else {
        console.log('add', rewardActivity);
        request.post('/cps/v1/activity/rewardActivity', rewardActivity).then((res) => {
          if (!res.success) return this.$message.error(res.message);
          this.$message.success(res.message);
          this.$emit('onClose');
          this.$emit('onGetList');
        });
      }
    },
  },
};
